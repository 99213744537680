/* eslint-disable react/display-name */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable quote-props */
/* eslint-disable react/prop-types */

import React from 'react'
import { MDBBtn, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import Input from './common/Input.jsx'
import Switch from './common/Switch.jsx'
import DomainConfig from './DomainConfig.jsx'
import { buildHomeDirectoryPattern } from '../utils'

export default props => (
	<form id="ftp_block" onSubmit={props.onSubmit}>
		<MDBRow>
			<MDBCol md="6" sm="12">
				{/* The following two line are used to prevent browser from autofilling Form fields */}
				<input type= "text" style={{display:"none"}}/>
				<input type="password" style={{display:"none"}}/>
				{/* end */}
				<Input
					type="password"
					id="ftp£_userPassword"
					onChange={props.onInputChange}
					pattern="^(?!.* ).{8,128}$"
					label="Password"
				/>
			</MDBCol>

			<MDBCol md="6" sm="12">
				<Input
					type="password"
					id="ftp£_conf_password"
					onChange={props.onInputChange}
					pattern="^(?!.* ).{8,128}$"
					label="Cambia password"
				/>
			</MDBCol>
		</MDBRow>
		<MDBRow className="mb-2">
			<MDBCol md="12">
				<p>
					<small className="orange-text">
						{' '}
						La password, se inserita, deve essere tra 8 e 128 caratteri e non contenere gli spazi. La nuova
						password verrà salvata solo se i valori delle 2 password inserite sono uguali.
					</small>
				</p>
			</MDBCol>
		</MDBRow>
		<MDBRow className="mb-2">
			<MDBCol md="6" sm="12">
				<Input
					type="text"
					id="ftp£_uid"
					onChange={props.onInputChange}
					label="UID"
					disabled={props.authType !== 'admin'}
					defaultValue={props.ftpUser.uid}
				/>
			</MDBCol>
			<MDBCol md="6" sm="12">
				<Input
					type="number"
					id="ftp£_uidNumber"
					label="UID Number"
					onChange={props.onInputChange}
					disabled={props.authType !== 'admin'}
					defaultValue={props.ftpUser.uidNumber}
				/>
			</MDBCol>
		</MDBRow>
		<MDBRow className="mb-2">
			<MDBCol md="6" sm="12">
				<Input
					type="number"
					id="ftp£_FTPQuotaMBytes"
					label="FTP Quota (In MB)"
					onChange={props.onInputChange}
					disabled={props.authType !== 'admin' || !props.ftpUser.objectClass.includes('PureFTPdUser')}
					defaultValue={props.ftpUser.FTPQuotaMBytes}
				/>
			</MDBCol>
			<MDBCol md="6" sm="12">
				<Input
					type="text"
					id="ftp£_homeDirectory"
					label="Home Directory"
					onChange={props.onInputChange}
					disabled={props.authType !== 'admin'}
					defaultValue={props.ftpUser.homeDirectory}
					pattern={buildHomeDirectoryPattern(props.domain.name)}
					
				/>
				<small className="text-muted">{`Percorsi definibili solo da /srv/www/${props.domain.name} in giù`}</small>
			</MDBCol>
		</MDBRow>
		<MDBRow className="mb-2">
			<MDBCol md="6" sm="12" className="mt-5">
				<Switch
					id="ftp£_FTPStatus"
					onChange={props.onSwitchChange}
					className="custom-control-inline"
					disabled={props.authType !== 'admin' || !props.ftpUser.objectClass.includes('PureFTPdUser')}
					defaultChecked={!props.ftpUser.FTPStatus}
					label="FTP"
				/>
				<Switch
					id="ftp£_editAccounts"
					onChange={props.onSwitchChange}
					className="custom-control-inline"
					disabled={props.authType !== 'admin'}
					defaultChecked={props.ftpUser.editAccounts}
					label="Può editare gli account"
				/>
			</MDBCol>
			<MDBCol md="6" sm="12" className="mt-2">
				<Input
					type="number"
					id="ftp£_gidNumber"
					label="GID Number"
					disabled={props.authType !== 'admin'}
					onChange={props.onInputChange}
					defaultValue={props.ftpUser.gidNumber}
				/>
			</MDBCol>
		</MDBRow>
		<hr />

		<DomainConfig onDomainInputChange={props.onDomainInputChange} onDomainSwitchChange={props.onDomainSwitchChange} authType={props.authType} domain={props.domain} />
		<MDBRow className="justify-content-center mb-4 pt-4">
			<MDBBtn type="submit" color="success">
				<MDBIcon far icon="save" size="lg" /> Salva
			</MDBBtn>
		</MDBRow>
	</form>
)
